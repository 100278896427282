import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '../../components/Fade';
import Form from '../../components/Games/Form';
import { getGame, getGames } from '../../store/actions';
import MainContainer from '../../components/MainContainer/MainContainer';

function FormWin() {
  const dispatch = useDispatch();
  const wins = useSelector(state => state.user.user?.wins || []);
  const isLogged = useSelector(state => state.auth.token);
  const lang = useSelector(state => state.app.config?.lang);
  const { game } = useSelector(state => state.promo || {});
  const { log, played, form } = game.length > 0 ? game[0] : {};

  useEffect(() => {
    if (wins[0]?.promotion && wins[0]?.game_id && wins[0]?.pending)
      dispatch(getGame(wins[0]?.game_id, wins[0]?.promotion, ''));
  }, [wins]);
  return (
    <Fade>
      {game.length > 0 && (
        <MainContainer style={{ marginTop: '135px' }}>
          <Form
            form={form}
            formResponse={log.form}
            lang={lang}
            id={wins[0]?.game_id}
            played={played}
            formWin
            playslog={log.playslog}
          />
        </MainContainer>
      )}
    </Fade>
  );
}

export default FormWin;
