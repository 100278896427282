import { createGlobalStyle } from 'styled-components';
import { respondTo } from '../../theme/mixin';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'abbvoice';
  src: url(${process.env.PUBLIC_URL + '/fonts/abbvoice-light.otf'});
  font-weight: 350;
}
@font-face {
  font-family: 'abbvoice';
  src: url(${process.env.PUBLIC_URL + '/fonts/abbvoice-bold.otf'});
  font-weight: bold;
}
@font-face {
  font-family: 'abbvoice';
  src: url(${process.env.PUBLIC_URL + '/fonts/abbvoice-medium.otf'});
  font-weight: 500;
}
@font-face {
  font-family: 'abbvoice';
  src: url(${process.env.PUBLIC_URL + '/fonts/abbvoice-regular.otf'});
  font-weight: 400;
}
  html {
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
    color: ${props => props.theme.text_primary};
  }
  body {
    font-family: ${props => props.theme.fontFamily};
    font-size: 100%;
    /* height: 100%; */
    background-color: #F0F0F0;

/*     ${respondTo.sm`
      background-color: #EAF0F3;
    `} */
  }
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.secondary};
    font-family: ${props => props.theme.fontFamily};
  }
  
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .md_mb-10 {
    margin-bottom: 10px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .sm_mb-20 {
    margin-bottom: 20px;

    ${respondTo.sm`
      margin-bottom: 0;
    `}
  }
  .sm_mt-20 {
    margin-top: 20px;

    ${respondTo.sm`
      margin-top: 0;
    `}
  }
  .md_mb-20 {
    margin-bottom: 20px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .md_mb-30 {
    margin-bottom: 30px;

    ${respondTo.md`
      margin-bottom: 0;
    `}
  }
  .sm_mb-30 {
    margin-bottom: 30px;

    ${respondTo.sm`
      margin-bottom: 0;
    `}
  }

  .mt-10 {
    margin-top: 10px;
  }
  .mt-12 {
    margin-top: 12px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-32 {
    margin-top: 32px;
  }
  .md_mt-10 {
    margin-top: 10px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-20 {
    margin-top: 20px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .md_mt-30 {
    margin-top: 30px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
  .mt--50{
    margin-top: 30px !important;
    z-index: 1;
    position: relative;
    ${respondTo.sm`
    margin-top: -50px !important;
  `}
  }
  .p-t130{
    ${respondTo.sm`   
        padding: 130px 0 0 0;
    `}
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .only-mobile {
    display: none;

    ${respondTo.sm`
      display: block;
    `}
  }
  .only-desktop {
    display: block;

    ${respondTo.sm`
      display: none;
    `}
  }
    .text-underline {
      text-decoration: underline;
      color: ${props => props.theme.primary};
    }

    
`;

export default GlobalStyle;
