import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ReactComponent as Hint } from '../../../assets/images/hint.svg';
import Checkbox from './Checkbox/Checkbox';
import Datepicker from './Datepicker';
import File from './File';
import InputField from './InputField/InputField';
import InputPrice from './InputPrice';
import InputTime from './InputTime';
import Radio from './Radio';
import Select from './Select/Select';
import Textarea from './Textarea/Textarea';
import ReactSelect from 'react-select';
import { minHeight } from 'styled-system';

const ReactSelectStyle = styled(ReactSelect)`
  width: 100%;
  border-radius: 15px;

  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.transparent
        ? 'transparent'
        : 'transparent';
    }};

  .select__indicators {
    // display: none;
    /*    svg {
      fill: green;
      stroke: green;
    } */
  }

  .select__control {
    box-shadow: none;
    &:hover {
      border: none;
    }
  }
`;
const BoxInput = styled.div`
  display: flex;
  flex: 1;
  > div {
    flex-grow: 1;
  }
`;
const InputContainer = styled.div`
  .flexInfo {
    display: flex;
    & > div:first-child {
      flex-grow: 1;
    }

    overflow: hidden;
    ${props =>
      props.type !== 'checkbox' &&
      css`
        border-radius: 16px;
      `}
  }

  .info {
    cursor: pointer;
    background-color: ${({ type }) => type !== 'checkbox' && type !== 'radio' && '#f0f0f0'};
    padding: 0 10px;
    display: flex;
    align-items: center;
    svg {
      color: #808080;
    }
  }
  margin-bottom: 20px;
  /* display: ${props => (props.type === 'checkbox' ? 'flex' : 'block')}; */
`;

const ErrorContainer = styled.p`
  margin-top: 5px;
  margin-left: 5px;
  color: #921a28;
  font-size: 10px;
`;

const TextP = styled.p`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  margin-top: 24px;
`;
const listInitiativa = [
  'Invito ricevuto via email',
  'Mail inviata da funzionario ABB',
  'Mail iniviata da operatore PdV',
  'Motore di ricerca',
  'Passaparola',
  'Pubblicità online',
  'Segnalazione di un operatore sul punto vendita',
  'Social Media',
  'Spot radiofonico',
  'Altro',
];
const Input = props => {
  let label = null;
  let inputElement = null;
  let title = null;
  const theme = useTheme();
  const [startDate, setStartDate] = useState(null);
  if (props.title) {
    title = <TextP {...props}>{props.title}</TextP>;
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <InputField
          {...props.elementConfig}
          onChange={props.changed}
          {...props}
          value={props.value}
          readOnly={props.readonly}
        />
      );
      break;
    case 'select':
      let options = null;
      if (props.isCountry) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {props.countries.map(opt => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </>
        );

        //} else if (props.elementConfig.placeholder === 'Provincia') {
      } else if (props.isProvince) {
        options = (
          <>
            {props.elementConfig.options?.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}

            {props.provinces.map(opt => (
              <option value={opt.value} key={opt.id}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isDistrict) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.districts.map((opt, index) => (
              <option value={props.isDistributer ? opt.name : opt.value} key={opt.value + index}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isWholesalers) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.wholesalers.map((opt, index) => (
              <option value={opt.id} key={opt.value + index}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isCity) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {props.cities.map(opt => (
              <option value={opt.name} key={opt.name}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isPdv) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {props.pdv.map(opt => (
              <option value={opt.pdv} key={opt.pdv}>
                {opt.pdv}
              </option>
            ))}
          </>
        );
      } else if (props.isHowKnow) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {listInitiativa.map(opt => (
              <option value={opt} key={opt}>
                {opt}
              </option>
            ))}
          </>
        );
      } else if (props.isTopic) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {props.topics.map(opt => (
              <option value={opt.id} key={opt.id}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isOrder) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option
                value={opt.value}
                key={opt.value}
                disabled={opt.disabled}
                className='default'
                selected
              >
                {opt.displayValue}
              </option>
            ))}
            {props.orders.map(opt => (
              <option value={opt.order_number} key={opt.id}>
                {String(opt.order_number)}
              </option>
            ))}
          </>
        );
      } else {
        options = props.elementConfig.options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.displayValue}
          </option>
        ));
      }

      inputElement = props.isMulti ? (
        <ReactSelectStyle
          className='basic-single'
          classNamePrefix='select'
          isDisabled={props.elementConfig.disabled}
          //isLoading={isLoading}
          isClearable={true}
          isSearchable={true}
          onChange={(value, action) => {
            props.changed(props.elementConfig.name, value);
          }}
          name={props.elementConfig.name}
          isMuli
          options={[
            ...props.elementConfig.options.map(i => ({ label: i.displayValue, value: i.value })),
          ]}
          placeholder={props.label}
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: '32px',
              borderRadius: '15px',
              border: 'none',
              backgroundColor: theme?.input?.bg,
              borderRadius: theme?.border_radius_generale_campi,
              fontSize: '12px',
              fontWeight: '500',
              padding: '0px 7px',
              borderRadius: '32px',
              minHeight: '32px',
            }),
            InputContainer: (provided, state) => ({
              ...provided,
              margin: '0px',
            }),
            dropdownIndicator: styles => ({
              ...styles,
              color: '#a9a9a9',
              width: '29px',

              justifyContent: 'end',
              padding: '6px 2px 6px 8px',
            }),
            indicatorSeparator: styles => ({
              ...styles,
              display: 'none',
            }),
            clearIndicator: styles => ({
              ...styles,
              color: '#a9a9a9',
              padding: 0,
            }),

            menu: styles => ({
              ...styles,
              borderBottom: '1px solid #e0e0e0',
              fontSize: '12px',
              fontWeight: '500',
              marginTop: '0px',
            }),
            placeholder: defaultStyles => {
              return {
                ...defaultStyles,
                color: '#47545d59',
                marginRight: '10px',
              };
            },
          }}
          {...props}
        />
      ) : (
        <Select
          onChange={props.changed}
          name={props.elementConfig.name}
          disabled={props.elementConfig.disabled}
          className='select-gender'
          {...props}
        >
          {options}
        </Select>
      );
      break;
    case 'datepicker':
      inputElement = (
        <Datepicker
          lang={props.lang}
          {...props.elementConfig}
          selected={props.startdate}
          onChange={props.changedDatePicker}
          minDate={
            props.validation.minDate
              ? props.validation.minDate === 'today'
                ? new Date()
                : new Date(props.validation.minDate)
              : null
          }
          {...props}
          maxDate={
            props.validation.maxDate
              ? props.validation.maxDate === 'today'
                ? new Date()
                : new Date(props.validation.maxDate)
              : null
          }
          {...props}
        />
      );
      break;
    case 'inputcheckbox':
      inputElement = (
        <Checkbox
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          onClick={() => (props.readonly ? 'return false;' : '')}
          {...props}
        />
      );
      break;
    case 'inputradio':
      inputElement = (
        <Radio
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          readonly={props.readonly}
          {...props}
        />
      );
      break;
    case 'inputfile':
      inputElement = (
        <File
          {...props.elementConfig}
          handleFile={props.fileUploadHandler}
          imagePreviewUrl={props.imagePreviewUrl}
          deleteFileHandler={props.deleteFileHandler}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'price':
      inputElement = (
        <InputPrice
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'inputtime':
      inputElement = (
        <InputTime
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <Textarea
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          className='textarea'
          {...props}
        />
      );
      break;
    default:
      inputElement = <input />;
  }

  return (
    <React.Fragment>
      {title}
      <InputContainer type={props.elementConfig.type} {...props} className='input-container'>
        <div className='flexInfo'>
          <div className={props.name}>
            {label && props.elementConfig?.type !== 'checkbox' ? label : null}
            <BoxInput className={props.elementType == 'select' ? 'inputSelect' : ''}>
              {inputElement}
              {/* {props.elementType === 'select' && <MdKeyboardArrowDown />} */}
            </BoxInput>
          </div>

          {props.hint && (
            <div className='info'>
              <Hint onClick={() => props.clickHintHandler(props.hint)} />
            </div>
          )}
        </div>
        {props.hintPwd && (
          <p style={{ fontSize: '12px', marginTop: '5px', marginLeft: '5px' }}>{props.hintPwd}</p>
        )}
        <ErrorContainer>
          {(!props.isvalid && !props.istouched && props.firstSubmit && props.showError) ||
          (!props.isvalid && props.showError)
            ? props.error
            : ''}
        </ErrorContainer>
      </InputContainer>
    </React.Fragment>
  );
};

export default Input;
