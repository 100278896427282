import moment from 'moment';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  customRegexValidation,
  getCities,
  getProvinceForm,
  getWholesalers,
  getWholesalersDistrict,
  getWholesalersCity,
  validateEmail,
  validatePassword,
  getDistricts,
} from '../utils/utils';

const useForm = (
  callback,
  formState,
  datepickername,
  resetForm = false,
  isConvalida = false,
  isForm,
  updater,
) => {
  const [formData, setFormData] = useState(formState);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [control, setControl] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [startDate, setStartDate] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const [regions, setRegions] = useState([]);
  const [pdv, setPdv] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [wholesalers, setWholesalers] = useState([]);
  const isLogged = useSelector(state => state.auth.token);
  useEffect(() => {
    setFormData(formState);
  }, [updater]);
  const checkValidity = (value, rules, target) => {
    let isValid = true;

    if (rules.required) {
      isValid = String(value).trim() !== '' && isValid;
      if (target.type === 'checkbox') {
        if (target.checked === true) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
      if (target.type === 'radio') {
        isValid = true;
        if (rules.requiredValue) {
          if (target.value == rules.requiredValue) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
      }
      if (target.type === 'file') {
        if (target.value) {
          isValid = true;
        } else {
          isValid = false;
        }
      }
    }
    if (rules.isEmail) {
      isValid = validateEmail(value) && isValid;
    }
    if (rules.isPassword) {
      isValid = validatePassword(value) && isValid;
    }
    if (rules.isConfirmPassword) {
      isValid = value === passwordValue && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isCustomCode) {
      isValid = customRegexValidation(rules.regex, value) && isValid;
    }
    return isValid;
  };
  const selectChange = (name, value) => {
    const updateFormData = {
      ...formData,
    };
    const updateFormDataElement = {
      ...updateFormData[name],
    };

    updateFormDataElement.value = value;
    updateFormDataElement.valid = value.length > 0 ? true : false;
    updateFormDataElement.idPdv = value?.id;
    updateFormDataElement.touched = true;
    updateFormData[name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };
  const inputChangedHandler = event => {
    const updateFormData = {
      ...formData,
    };

    if (event.target.name === 'country') {
      setDistricts([]);
      if (event.target.value === 'IT') {
        if (updateFormData['country'].isDistributer) {
          updateFormData['wholesaler_id'].value = '';
          getWholesalers().then(data => {
            setDistricts(data);
            setWholesalers([]);
          });
        } else {
          getProvinceForm().then(data => {
            setDistricts(data);
          });
        }

        updateFormData['district'].value = '';
        updateFormData['city'].value = '';

        setCities([]);
        if (updateFormData['vatcode']) {
          updateFormData['vatcode'].elementConfig.placeholder = 'P.IVA*';
          updateFormData['vatcode'].hint.field = 'Partita IVA';
          updateFormData[
            'vatcode'
          ].hint.hint = `Serie di 11 numeri che identifica il tuo locale es:00112233445`;
        }
      } else {
        getWholesalersCity('sanmarino').then(data => {
          setWholesalers(data);
        });
        setDistricts([{ name: 'San Marino', value: 'SM' }]);
        setCities([{ name: 'San Marino', value: 'SM' }]);
        updateFormData['district'].valid = true;
        updateFormData['district'].value = 'SM';
        updateFormData['city'].valid = true;
        updateFormData['city'].value = 'SM';
        if (updateFormData['vatcode']) {
          updateFormData['vatcode'].elementConfig.placeholder = 'C0E*';
          updateFormData['vatcode'].hint.field = 'C0E*';
          updateFormData['vatcode'].hint.hint =
            'Serie di 5 numeri che identifica il tuo locale es:12345';
        }
      }
    }

    if (event.target.name === 'district') {
      setPdv([]);
      setCities([]);

      // getPDV(event.target.value).then(data => {
      //   setPdv(data);
      // });
      updateFormData['city'].value = '';
      if (event.target.value === 'SM') {
        setCities([{ name: 'San Marino', value: 'SM' }]);
      } else {
        if (updateFormData['district'].isDistributer) {
          updateFormData['wholesaler_id'].value = '';
          let districtValue = districts.filter(district => district.name === event.target.value)[0]
            .value;

          getWholesalersDistrict(districtValue).then(data => {
            setCities(data);
            setWholesalers([]);
          });
        } else {
          getCities(event.target.value).then(data => {
            setCities(data);
          });
        }
      }
    }

    if (event.target.name === 'topic') {
      if (+event.target.value !== 1) {
        updateFormData['contactusTopic'].valid = true;
      } else {
        updateFormData['contactusTopic'].valid = false;
      }
    }
    if (event.target.name === 'city' && updateFormData['zipcode']) {
      let zipcode = cities.filter(city => city.name === event.target.value)[0].zipcode;
      setZipcode(zipcode);
      updateFormData['zipcode'].valid = true;
      updateFormData['zipcode'].value = cities.filter(
        city => city.name === event.target.value,
      )[0].zipcode;
    } else if (event.target.name === 'city' && updateFormData['city'].isDistributer) {
      updateFormData['wholesaler_id'].value = '';
      let cityValue = cities.filter(city => city.name === event.target.value)[0].value;
      getWholesalersCity(cityValue).then(data => {
        setWholesalers(data);
      });
    }

    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.value =
      event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid && formIsValid;
    }

    if (updateFormDataElement.validation.toMatch) {
      setPasswordValue(updateFormDataElement.value);
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  const resetFormHandler = () => {
    const updateFormData = {
      ...formData,
    };

    for (let i in updateFormData) {
      if (updateFormData[i].elementType === 'inputfile') {
        if (updateFormData[i].valid === true) {
          updateFormData[i].valid = true;
        }
      } else {
        updateFormData[i].value = '';
        updateFormData[i].valid = false;
      }
    }
    setFormData(updateFormData);
  };

  const formSubmitHandler = event => {
    event.preventDefault();

    setFirstSubmit(true);
    if (control) {
      setShowError(true);
    }
    let formDataObj = {};
    for (let formEl in formData) {
      if (formData[formEl].valuetosend) {
        formDataObj[formEl] = formData[formEl].valuetosend;
      } else {
        formDataObj[formEl] = formData[formEl].value;
      }
    }
    let checkoutValid = true;

    for (let key in formData) {
      checkoutValid = formData[key].valid && checkoutValid;
    }
    if (isConvalida && formData['prize'].value === '2') {
      callback(formDataObj);
    }

    if (formIsValid || checkoutValid) {
      callback(formDataObj);
    }
  };

  const datePickerHandler = date => {
    setStartDate(date);
  };

  const fileUploadHandler = event => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file !== undefined) {
      setSelectedFile(file);

      const updateFormData = {
        ...formData,
      };
      const updateFormDataElement = {
        ...updateFormData[event.target.name],
      };

      updateFormDataElement.valid = checkValidity(
        updateFormDataElement.value,
        updateFormData[event.target.name].validation,
        event.target,
      );

      updateFormDataElement.touched = true;
      updateFormData[event.target.name] = updateFormDataElement;

      let formIsValid = true;
      for (let key in updateFormData) {
        formIsValid = updateFormData[key].valid && formIsValid;
      }

      setControl(true);
      setFormIsValid(formIsValid);
      setFormData(updateFormData);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setImgPreviewUrl(reader.result);
        } else {
          //setImgPreviewUrl(reader.result);
          //immagine di placeholder
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl(null);
    }
  };

  const deleteFileHandler = () => {
    if (resetForm) {
      resetFormHandler();
      setFormIsValid(false);
    }
    setImgPreviewUrl(null);
  };
  useEffect(
    () => {
      if (formData?.district?.value !== 'San Marino') {
        getProvinceForm().then(data => {
          setDistricts(data);
        });
      }
    },
    [
      /* formData?.district?.value */
    ],
  );
  useEffect(() => {
    if (cities.length === 0) {
      setZipcode('');
    }
  }, [cities]);

  useEffect(() => {
    if (startDate !== null) {
      for (var i = 0; i < datepickername.length; i++) {
        const updateFormData = {
          ...formData,
        };
        const updateFormDataElement = {
          ...updateFormData[`${datepickername[i]}`],
        };

        if (startDate !== null) {
          updateFormDataElement.value = startDate;
          updateFormDataElement.valuetosend = moment(startDate).format('L');
        }

        updateFormDataElement.valid = true;
        updateFormDataElement.touched = true;
        updateFormData[`${datepickername[i]}`] = updateFormDataElement;

        let formIsValid = true;
        for (let key in updateFormData) {
          formIsValid = updateFormData[key].valid && formIsValid;
        }

        setControl(true);
        setFormIsValid(formIsValid);
        setFormData(updateFormData);
      }
    }
  }, [startDate]);

  const setFormValid = event => {
    const updateFormData = {
      ...formData,
    };
    const updateFormDataElement = {
      ...updateFormData[event.target.name],
    };

    updateFormDataElement.valid = checkValidity(
      updateFormDataElement.value,
      updateFormData[event.target.name].validation,
      event.target,
    );

    updateFormDataElement.touched = true;
    updateFormData[event.target.name] = updateFormDataElement;

    let formIsValid = true;
    for (let key in updateFormData) {
      formIsValid = updateFormData[key].valid;
    }

    setControl(true);
    setFormIsValid(formIsValid);
    setFormData(updateFormData);
  };

  const setValue = (name, value) => {
    setFormData(prev => {
      const updateFormData = {
        ...prev,
      };
      const updateFormDataElement = {
        ...updateFormData[name],
      };

      updateFormDataElement.value = value;
      updateFormData[name] = updateFormDataElement;
      return updateFormData;
    });
  };

  return {
    inputChangedHandler,
    formSubmitHandler,
    formData,
    firstSubmit,
    setFirstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    formIsValid,
    resetFormHandler,
    regions,
    districts,
    pdv,
    cities,
    provinces,
    zipcode,
    setFormValid,
    setShowError,
    wholesalers,
    setValue,
    setCities,
    selectChange,
  };
};

export default useForm;
